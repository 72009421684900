import { JsonPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterLink,
  RouterLinkActive,
  RouterStateSnapshot,
} from '@angular/router';
import { GoogleMapComponent } from 'src/app/components/_elements/google-map/google-map.component';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { FormFieldComponent } from 'src/app/components/form/form-field/form-field.component';
import { GmapInputComponent } from 'src/app/components/form/input/gmap-input.component';
import { InputComponent } from 'src/app/components/form/input/input.component';
import { SelectComponent } from 'src/app/components/form/select/select.component';
import { asObject } from 'src/app/enum';
import { Status } from 'src/app/enum/status';
import { LocationsService } from 'src/app/services/locations.service';

export const locationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const { id } = route.params || {};
  return inject(LocationsService).getLocationById(id);
};

@Component({
  selector: 'location-view',
  templateUrl: './location.view.html',
  styleUrls: ['./location.view.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    SelectComponent,
    InputComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    JsonPipe,
    ViewHeaderComponent,
    ViewContentComponent,
    GoogleMapComponent,
    GmapInputComponent,
  ],
  host: {
    id: 'view',
  },
})
export class LocationViewComponent implements OnInit {
  public formGroup: FormGroup;

  public edit = true;

  public statusOptions = [Status.Active, Status.Disabled].map((x) =>
    asObject(Status, Number(x)),
  );

  // public statusOptions = [
  //   {
  //     name: 'Aktiivne',
  //   },
  // ];

  public formatOptions = [
    {
      name: 'Mugavuskauplus',
    },
    {
      name: 'Ebamugavuskauplus',
    },
  ];

  public formData: any;

  constructor(private _route: ActivatedRoute) {
    const { data } = _route.snapshot.data || {};
    const { location, formData } = data || {};

    const {
      address,
      addressObject,
      status,
      chainBrand,
      name,
      email,
      gln,
      format,
    } = location || {};

    this.formData = formData;

    this.formGroup = new FormGroup({
      status: new FormControl(status),
      chainBrand: new FormControl(chainBrand?.id),
      name: new FormControl(name),
      gln: new FormControl(gln),
      format: new FormControl(format?.id),
      salesArea: new FormControl(),
      email: new FormControl(email),
      address: new FormControl(address),
      gmap_address: new FormControl(addressObject),
    });
  }

  ngOnInit() {}
}
