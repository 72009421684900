<view-header
  [label]="formGroup.value.name || 'Asukoht'"
  [backRoute]="{ label: 'Asukohad', route: '/locations'}"
>
</view-header>

<view-content>
  <form [formGroup]="formGroup">
    <div class="mb-5x min-h-60 min-w-full lg:min-h-80">
      <google-map for="gmap_address" [readonly]="!edit"></google-map>
    </div>

    <form-field label="Staatus" [required]="true">
      <app-select
        for="status"
        [options]="statusOptions"
        [clearable]="false"
        value="id"
      ></app-select>
    </form-field>

    <form-field label="Ketimärk" [required]="true">
      <!-- <app-input for="chainBrand"></app-input> -->
      <app-select
        for="chainBrand"
        [options]="formData.chainBrands"
        [clearable]="false"
        labelField="value"
        value="id"
      ></app-select>
    </form-field>

    <form-field label="Asukoha nimetus" [required]="true">
      <app-input for="name"></app-input>
    </form-field>

    <form-field label="GLN" [required]="true">
      <app-input for="gln"></app-input>
    </form-field>

    <form-field label="Formaat" [required]="true">
      <app-select
        for="format"
        [options]="formData.storeFormats"
        labelField="value"
        value="id"
      ></app-select>
    </form-field>

    <form-field label="Müügipind">
      <app-input for="salesArea"></app-input>
    </form-field>

    <form-field label="E-post">
      <app-input for="email"></app-input>
    </form-field>

    <form-field label="Aadress">
      <app-gmap-input
        googlePlaceFor="gmap_address"
        for="address"
      ></app-gmap-input>
    </form-field>

    <button
      class="mt-7 min-w-full"
      appButton
      size="medium"
      color="primary"
      label="Salvesta"
      icon="monochrome-save.svg"
    ></button>
  </form>
</view-content>
