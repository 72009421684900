import {
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { API_KEYS } from 'src/app/config/api-keys';
import { Subscription } from 'rxjs';
import { GmapInputComponent } from '../../form/input/gmap-input.component';

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [GmapInputComponent, ReactiveFormsModule],
})
export class GoogleMapComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public for: string;

  @Input() public showInput = false;
  @Input() public readonly = false;

  @Input() public zoom = 18;
  @Input() public autocompleteOptions: google.maps.places.AutocompleteOptions =
    {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: [],
    };

  public loader = new Loader({
    apiKey: API_KEYS.GOOGLE_MAPS,
    version: 'weekly',
    libraries: ['places'],
  });

  public hideInput = false;

  private _changeSubscription: Subscription;
  private _streetviewListener: google.maps.MapsEventListener;

  public map: google.maps.Map;

  constructor(
    @Optional() @Host() private _controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
    if (this.formGroup && this.for) {
      const ref = this.formGroup.get(this.for);

      this.loadMap(ref.value);

      this._changeSubscription = ref.valueChanges.subscribe(
        (place: google.maps.places.PlaceResult) => {
          this.loadMap(place);
        },
      );
    }
  }

  public createMarker(
    location: google.maps.LatLng | null | google.maps.LatLngLiteral | undefined,
    map = this.map,
  ): google.maps.Marker {
    if (!location) return null;
    const marker = new google.maps.Marker({
      map,
      position: location,
    });
    return marker;
  }

  public loadMap(place: google.maps.places.PlaceResult | any) {
    // if ('google' in window && typeof google === 'object' && typeof google.maps === 'object') {
    //   this.initMap(place);
    // } else {
    this.loader.load().then(() => {
      this.initMap(place);
    });
    // }
  }

  public initMap(place: google.maps.places.PlaceResult | any) {
    if (!place) return;

    const geometry = place.geometry;
    const location = geometry?.location;
    const viewport = geometry?.viewport;

    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: location,
        zoom: this.zoom,
        mapTypeControl: false,
        // gestureHandling: "cooperative"
      },
    );

    if (this._streetviewListener) {
      this._streetviewListener.remove();
    }

    this._streetviewListener = google.maps.event.addListener(
      this.map.getStreetView(),
      'visible_changed',
      (_) => {
        this.hideInput = !this.hideInput;
      },
    );

    const marker = this.createMarker(location);

    if (!geometry || !location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      // window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (viewport) {
      this.map.fitBounds(viewport);
    } else {
      this.map.setCenter(location);
      this.map.setZoom(this.zoom);
    }

    marker.setPosition(location);
    marker.setVisible(true);
  }

  ngOnDestroy() {
    if (this._changeSubscription) {
      this._changeSubscription.unsubscribe();
    }
    if (this._streetviewListener) {
      this._streetviewListener.remove();
    }
  }
}
