<!-- <ng-container [formGroup]="formGroup" [ngSwitch]="readonly">
  <ng-container *ngSwitchDefault [ngSwitch]="type">
    <input
      *ngSwitchDefault
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [type]="type"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [disabled]="disabled"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      (blur)="blur.emit($event)"
      #input
    />

    <input
      *ngSwitchCase="'price'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [type]="type"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      (blur)="blur.emit($event)"
      mask="separator.{{ pricePrecision }}"
      prefix="{{ currencyPrefix }} "
      decimalMarker=","
      #input
    />

    <input
      *ngSwitchCase="'numeric'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      [allowNegativeNumbers]="true"
      (blur)="blur.emit($event)"
      mask="separator.{{ digits }}"
      decimalMarker=","
      #input
    />

    <input
      *ngSwitchCase="'percent'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      (blur)="blur.emit($event)"
      mask="separator.{{ digits }}"
      prefix="% "
      decimalMarker=","
      [allowNegativeNumbers]="true"
      #input
    />
  </ng-container>

  <ng-container *ngSwitchCase="true">
    <span class="lxm-input input-readonly full-width" [ngClass]="inputClass">
      {{ inputValue }}
    </span>
  </ng-container>

  <ng-content></ng-content>
</ng-container> -->
<ng-container [formGroup]="formGroup">
  <input
    class="inline-flex max-w-full flex-1 overflow-ellipsis outline-none"
    #input
    [id]="for"
    [type]="type"
    [formControlName]="for"
    [autocomplete]="autocomplete"
    [attr.disabled]="disabled"
    [mask]="mask ? mask.mask : null"
    [prefix]="mask ? mask.prefix : ''"
    [suffix]="mask ? mask.suffix : suffix"
    [allowNegativeNumbers]="mask ? mask.allowNegativeNumbers : null"
    [decimalMarker]="mask ? mask.decimalMarker : null"
    (blur)="blur.emit($event)"
  />

  @if (!readonly) {
    @if (clearable && hasValue) {
      <div
        class="icons-container bg-inherit absolute right-0 top-0 flex h-full cursor-pointer items-center gap-2 px-5x"
        (click)="clearValue()"
        matRipple
      >
        <div class="icon-container flex size-7x items-center justify-center">
          <svg-icon src="assets/icons/monochrome-x.svg"></svg-icon>
        </div>
        <ng-content select="[right]"></ng-content>
      </div>
    }
  }
</ng-container>
